import {Fragment, lazy, useEffect} from 'react';
import {Route, BrowserRouter as Router, Routes, useLocation, useNavigate} from 'react-router-dom';
import {NavigationView} from './navigation';
import {RenderWithSuspense} from './components/RenderWithSuspense';
import HomeWrapper from './pages/home-pages/home-wrapper';
import MainPage from './pages/home-pages/main-page';
import {useI18n} from './hooks/use-i18n';
import CanonicalLinkHead from './components/CanonicalLinkHead';

// Home Lazy
const HomePage404 = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/page-404'));
const FAQPage = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/faq-page'));
const ContactUs = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/contact-us'));
const SetNewPasswordPage = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/set-new-password-page'));
const SentPasswordResetRequestPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/home-pages/sent-password-reset-request-page'),
);
const ModelPage = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/model-page'));
const EmailNotificationUnsubscribeSuccessPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/home-pages/email-notification-unsubscribe-success-page'),
);

// App Lazy
const InnerFaq = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/inner-faq'));
const VerificationPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/verification-page'),
);
const SafetyTipsPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/safety-tips-page'),
);
const AppPage404 = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/page-404'));
const AppWrapper = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/app-wrapper'));
const ChatTabWrapper = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/chat-tab-wrapper'));
const SearchWrapper = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/search-page/search-wrapper'),
);
const SearchPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/search-page/search-page'));
const ModelSearchProfilePage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/search-page/model-search-profile-page'),
);
const ChatPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/chat-page'));
const AlbumsPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/albums-tab/albums-page'));
const AlbumsPageWrapper = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/albums-tab/albums-page-wrapper'),
);
const CreateAlbumPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/albums-tab/create-album-page'),
);
const EditAlbumPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/albums-tab/albums-edit-page'));
const ChatProfilePage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/chat-profile-page'));
const SettingsTabWrapper = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/settings-tab-wrapper'),
);
const ProfileSettingsPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/profile-settings-page'),
);
const FinanceTabWrapper = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/finance-tab-wrapper'),
);
const FinancePage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/finance-page'),
);
const WithdrawalMethodsPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/withdrawal-methods-page'),
);
const AddWithdrawalMethodPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/add-withdrawal-method-page'),
);
const EditWithdrawalMethodPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/edit-withdrawal-method-page'),
);
const LanguagePage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/language-page'));
const SupportPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/support-page'));

const LanguageWrapper = ({children}: {children: React.ReactNode}) => {
  const {language, updateInterfaceLanguage} = useI18n();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const navigateToLanguagePathIfNeeded = () => {
      const pathSegments = location.pathname.split('/');
      const isLoggedIn = pathSegments[1] === 'app';
      const isRuPath = pathSegments[1] === 'ru';
      const searchParams = location.search;

      if (isLoggedIn) {
        // language paths only work for landing pages
        return;
      }

      if (isRuPath && language !== 'ru') {
        // Navigate to non-ru path if language is not Russian
        // Only replace /ru/ at the start of the path to avoid affecting paths like /rumors
        // We need two replaces to handle these cases:
        // First replace: /ru/something → /something
        // Second replace: /ru → /
        const newPath = location.pathname.replace(/^\/ru\//, '/').replace(/^\/ru$/, '/');
        navigate(`${newPath}${searchParams}`);
      } else if (!isRuPath && language === 'ru') {
        // Redirect to Russian version if needed
        navigate(`/ru${location.pathname}${searchParams}`);
      }
    };

    navigateToLanguagePathIfNeeded();
  }, [location.pathname, language, navigate, updateInterfaceLanguage, location.search]);

  return <>{children}</>;
};

const LandingRoutes = () => {
  return (
    <Fragment>
      <Route path="login/:method?/:verifier?/:token?" element={<MainPage showLoginModalOnMount={true} />} />
      <Route index element={<MainPage showLoginModalOnMount={false} />} />
      <Route path="redirect" element={<MainPage showLoginModalOnMount={false} />} />
      <Route
        path="reset-password/new/:token"
        element={
          <RenderWithSuspense>
            <SetNewPasswordPage />
          </RenderWithSuspense>
        }
      />
      <Route
        path="reset-password/request"
        element={
          <RenderWithSuspense>
            <SentPasswordResetRequestPage />
          </RenderWithSuspense>
        }
      />
      <Route
        path="email-notification/unsubscribe/success"
        element={
          <RenderWithSuspense>
            <EmailNotificationUnsubscribeSuccessPage />
          </RenderWithSuspense>
        }
      />
      <Route
        path="*"
        element={
          <RenderWithSuspense>
            <HomePage404 />
          </RenderWithSuspense>
        }
      />
    </Fragment>
  );
};

export const router = (
  <Router>
    <CanonicalLinkHead />
    <LanguageWrapper>
      <Routes>
        {/* Russian routes */}
        <Route path="ru" element={<HomeWrapper />}>
          <Route>{LandingRoutes()}</Route>
        </Route>
        {/* English routes */}
        <Route path="/" element={<HomeWrapper />}>
          <Route>{LandingRoutes()}</Route>
        </Route>
        <Route
          path="app"
          element={
            <RenderWithSuspense>
              <AppWrapper />
            </RenderWithSuspense>
          }
        >
          <Route
            path="albums"
            element={
              <RenderWithSuspense>
                <AlbumsPageWrapper />
              </RenderWithSuspense>
            }
          >
            <Route
              index
              element={
                <RenderWithSuspense>
                  <AlbumsPage />
                </RenderWithSuspense>
              }
            />
            <Route
              path="create"
              element={
                <RenderWithSuspense>
                  <CreateAlbumPage />
                </RenderWithSuspense>
              }
            />
            <Route
              path=":albumId/edit"
              element={
                <RenderWithSuspense>
                  <EditAlbumPage />
                </RenderWithSuspense>
              }
            />
          </Route>
          <Route
            path="chat"
            element={
              <RenderWithSuspense>
                <ChatTabWrapper />
              </RenderWithSuspense>
            }
          >
            <Route
              path="search"
              element={
                <RenderWithSuspense>
                  <SearchWrapper />
                </RenderWithSuspense>
              }
            >
              <Route
                index
                element={
                  <RenderWithSuspense>
                    <SearchPage />
                  </RenderWithSuspense>
                }
              />
              <Route
                path="profile/:modelId"
                element={
                  <RenderWithSuspense>
                    <ModelSearchProfilePage />
                  </RenderWithSuspense>
                }
              />
            </Route>
            <Route path=":dialogId?">
              <Route
                index
                element={
                  <RenderWithSuspense>
                    <ChatPage />
                  </RenderWithSuspense>
                }
              />
              <Route
                path="profile"
                element={
                  <RenderWithSuspense>
                    <ChatProfilePage />
                  </RenderWithSuspense>
                }
              />
            </Route>
          </Route>
          <Route
            path="inner-faq"
            element={
              <RenderWithSuspense>
                <InnerFaq />
              </RenderWithSuspense>
            }
          />
          <Route
            path="support"
            element={
              <RenderWithSuspense>
                <SupportPage />
              </RenderWithSuspense>
            }
          />
          <Route
            path="settings"
            element={
              <RenderWithSuspense>
                <SettingsTabWrapper />
              </RenderWithSuspense>
            }
          >
            <Route
              path="profile"
              element={
                <RenderWithSuspense>
                  <ProfileSettingsPage />
                </RenderWithSuspense>
              }
            />
            <Route
              path="finance"
              element={
                <RenderWithSuspense>
                  <FinanceTabWrapper />
                </RenderWithSuspense>
              }
            >
              <Route
                index
                element={
                  <RenderWithSuspense>
                    <FinancePage />
                  </RenderWithSuspense>
                }
              />
              <Route
                path="withdrawal-methods"
                element={
                  <RenderWithSuspense>
                    <WithdrawalMethodsPage />
                  </RenderWithSuspense>
                }
              />
              <Route
                path="add-withdrawal-method/:method"
                element={
                  <RenderWithSuspense>
                    <AddWithdrawalMethodPage />
                  </RenderWithSuspense>
                }
              />
              <Route
                path="edit-withdrawal-method/:method"
                element={
                  <RenderWithSuspense>
                    <EditWithdrawalMethodPage />
                  </RenderWithSuspense>
                }
              />
            </Route>
            <Route
              path="language"
              element={
                <RenderWithSuspense>
                  <LanguagePage />
                </RenderWithSuspense>
              }
            />
            <Route
              path="verification"
              element={
                <RenderWithSuspense>
                  <VerificationPage />
                </RenderWithSuspense>
              }
            />
            <Route
              path="safety-tips"
              element={
                <RenderWithSuspense>
                  <SafetyTipsPage />
                </RenderWithSuspense>
              }
            />
          </Route>
          <Route
            path="not-found"
            element={
              <RenderWithSuspense>
                <AppPage404 />
              </RenderWithSuspense>
            }
          />
          <Route
            path="*"
            element={
              <RenderWithSuspense>
                <AppPage404 />
              </RenderWithSuspense>
            }
          />
        </Route>
      </Routes>
      <NavigationView />
    </LanguageWrapper>
  </Router>
);
