import React, {useState} from 'react';
import classNames from 'classnames';
import {Skeleton} from '../ui/Skeleton';
import {twMerge} from 'tailwind-merge';

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  skeletonClassName?: string;
  onLoad?: () => void;
}

export const ImageWithSkeleton = (props: Props) => {
  const {skeletonClassName, onLoad, ...rest} = props;
  const [wasLoaded, setLoaded] = useState(false);
  return (
    <>
      <img
        {...rest}
        alt={props.alt}
        onLoad={() => {
          setLoaded(true);
          onLoad?.();
        }}
        className={classNames(rest.className, {
          hidden: !wasLoaded,
        })}
      />
      {!wasLoaded && <Skeleton className={twMerge(rest.className, skeletonClassName)} />}
    </>
  );
};
