import {UserType} from '../../shared/user-type';
import {ReactNode, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import {LoadingIndicator} from '../../ui/LoadingIndicator';

export enum LoadingState {
  None = 'none',
  This = 'this',
  Other = 'other',
}

interface RoleProps {
  role: UserType;
  Icon: ReactNode;
  onClick: () => void;
  buttonText: string;
  description: string;
  loadingRole: UserType | null;
  className?: string;
}

export const Role = (props: RoleProps) => {
  const {Icon, buttonText, description, onClick, className, loadingRole, role} = props;
  const loadingState = useMemo(() => {
    switch (loadingRole) {
      case 'customer':
      case 'model':
        return role === loadingRole ? LoadingState.This : LoadingState.Other;
      default:
        return LoadingState.None;
    }
  }, [role, loadingRole]);

  const handleTouchEnd = (e: React.TouchEvent) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const touch = e.changedTouches[0];

    // Activate onClick only if user released touch inside the button
    if (
      touch.clientX >= buttonRect.left &&
      touch.clientX <= buttonRect.right &&
      touch.clientY >= buttonRect.top &&
      touch.clientY <= buttonRect.bottom
    ) {
      onClick();
    }
  };

  return (
    <div
      className={twMerge(
        'grid grid-cols-[auto,1fr] items-center gap-20 rounded border border-black-dark/12 p-30 px-20 text-left sm:block sm:p-30 sm:text-center',
        className,
      )}
    >
      <div className="inline-grid place-content-center rounded-full border-2 border-primary wh-48 sm:wh-64">{Icon}</div>
      <div className="text-gray-dark sm:mb-37 sm:mt-26">{buttonText}</div>
      <button
        onClick={onClick}
        onTouchEnd={handleTouchEnd}
        className={'btn btn--md btn--primary col-span-full w-full'}
        disabled={loadingState !== LoadingState.None}
      >
        <LoadingIndicator isLoading={loadingState === LoadingState.This} size={20} color={'white'} />
        {description}
      </button>
    </div>
  );
};
