import {lazy, useEffect} from 'react';
import {RenderWithSuspense} from '../../components/RenderWithSuspense';
import {useIsMobile} from '../../redux/hooks/use-is-mobile';
import ActiveLink from '../../components/ActiveLink';
import {Spacer} from '../../components/Spacer';
import {ReactComponent as IconAnonFun} from '../../assets/svg/anonfun-logo-with-name.svg';
import {twMerge} from 'tailwind-merge';
import {Skeleton} from '../../ui/Skeleton';
import {CardItem} from '../../modules/main/components/HowDoesItWorkCarouselMobile';
import s1 from '../../assets/lottie-animations/s1.json';
import s2 from '../../assets/lottie-animations/step-2_blur.json';
import s3 from '../../assets/lottie-animations/step-3_blur.json';
import {AlertModal} from '../../components/AlertModal';
import {useModalState} from '../../hooks/use-modal-state';
import {ImageWithSkeleton} from '../../components/ImageWithSkeleton';
import {di} from '../../dependency-injector';

// Lazy load components
const HowDoesItWorkCarouselMobile = lazy(
  () => import(/* webpackPrefetch: true */ '../../modules/main/components/HowDoesItWorkCarouselMobile'),
);
const HowDoesItWorkDesktop = lazy(
  () => import(/* webpackPrefetch: true */ '../../modules/main/components/HowDoesItWorkDesktop'),
);

const LanguagePage = () => {
  const isMobile = useIsMobile();
  const {isOpen, show, hide} = useModalState();
  const handleClick = () => {
    try {
      di.postbackService.trackLead();
      const currentUrl = new URL(window.location.href);
      const searchParams = currentUrl.searchParams.toString();
      const redirectUrl = `https://anonfun.com/${searchParams ? `?${searchParams}` : ''}`;
      window.location.href = redirectUrl;
    } catch (error) {
      window.location.href = 'https://anonfun.com';
    }
  };

  useEffect(() => {
    const ALERT_MODAL_THRESHOLD_MS = 3000; // 3 sec
    // Show modal if user hasn't left the site within threshold
    setTimeout(show, ALERT_MODAL_THRESHOLD_MS);
  }, [show]);

  useEffect(() => {
    di.postbackService.initLanding();
  }, []);

  const howDoesItWorkCards: CardItem[] = [
    {
      key: 'create-an-account',
      title: <>Create an account</>,
      animationSrc: s1,
      hide: isMobile,
    },
    {
      key: 'choose-model-you-like',
      title: <>Choose the model you like</>,
      animationSrc: s2,
    },
    {
      key: 'chat-and-buy-photos',
      title: <>Chat and buy photos</>,
      animationSrc: s3,
    },
  ].filter(({hide}) => !hide);

  return (
    <>
      <AlertModal
        isOpen={isOpen}
        hide={hide}
        title="Girls cant wait to chat with you"
        showButton={true}
        buttonText="Start chatting"
        onClick={handleClick}
      />
      <div className="mb-50">
        <div className="container mb-10 mt-20 flex w-full items-center lg:mb-20 lg:mt-25">
          <ActiveLink href="/">
            <div className={'flex flex-row gap-5'}>
              <IconAnonFun />
            </div>
          </ActiveLink>
          <Spacer />
        </div>
        <div className="overflow-hidden">
          <div className="container grid grid-cols-1 justify-items-center text-center lg:grid-cols-2 lg:text-left gap-10">
            <div className={'grid auto-rows-auto lg:w-full'}>
              <h2 className="pt-10 text-[2.4rem] font-bold sm:text-3xl lg:whitespace-normal lg:text-4xl">
                Spend time with beautiful real women and get their photos and videos
              </h2>
              <div className="mb-20 mt-15 text-sm text-gray-dark sm:text-base lg:mb-52 lg:mt-28 lg:text-lg">
                Only amateurs. No professionals or bots.
                <br />
                True connections
              </div>
              {isMobile && <MainIll />}
              <button
                className="btn btn--md btn--primary mx-auto lg:mx-0 lg:w-2/3 w-full h-70 text-[2.2rem]"
                onClick={handleClick}
              >
                Select model
              </button>
            </div>
            {!isMobile && <MainIll />}
          </div>
        </div>
        <div className="container mt-40 lg:mt-50 xl:mt-85">
          <h2 className="mb-28 text-center text-[2.6rem] font-bold sm:text-2xl lg:mb-50 lg:text-left lg:text-3xl xl:mb-85 xl:text-4xl">
            How does it work?
          </h2>
          {isMobile ? (
            <RenderWithSuspense fallback={<HowDoesItWorkMobileSkeleton />}>
              <HowDoesItWorkCarouselMobile items={howDoesItWorkCards} />
            </RenderWithSuspense>
          ) : (
            <RenderWithSuspense fallback={<HowDoesItWorkDesktopSkeleton items={howDoesItWorkCards} />}>
              <HowDoesItWorkDesktop items={howDoesItWorkCards} isCustomer={true} />
            </RenderWithSuspense>
          )}
          <button
            className="block btn btn--md btn--primary mx-auto lg:w-2/3 w-full h-70 text-[2.2rem] lg:mt-30"
            onClick={handleClick}
          >
            Select model
          </button>
        </div>
      </div>
    </>
  );
};

const mainIllSrc = {
  webp: {
    mobile: require('../../assets/img/main-ill-landing-640.webp'),
    tablet: require('../../assets/img/main-ill-landing-1024.webp'),
    desktop: require('../../assets/img/main-ill-landing-1920.webp'),
  },
  jpg: {
    mobile: require('../../assets/img/main-ill-landing-640.jpg'),
    tablet: require('../../assets/img/main-ill-landing-1024.jpg'),
    desktop: require('../../assets/img/main-ill-landing-1920.jpg'),
  },
};

const MainIll = ({className}: {className?: string}) => {
  return (
    <picture>
      <source media="(min-width: 1024px)" srcSet={mainIllSrc.webp.desktop} type="image/webp" />
      <source media="(min-width: 640px)" srcSet={mainIllSrc.webp.tablet} type="image/webp" />
      <source srcSet={mainIllSrc.webp.mobile} type="image/webp" />
      <source media="(min-width: 1024px)" srcSet={mainIllSrc.jpg.desktop} type="image/jpeg" />
      <source media="(min-width: 640px)" srcSet={mainIllSrc.jpg.tablet} type="image/jpeg" />
      <ImageWithSkeleton
        alt="main ill"
        src={mainIllSrc.jpg.mobile}
        loading="eager"
        decoding="async"
        className={twMerge('w-full h-auto rounded-lg mb-13', className)}
        skeletonClassName="h-450 lg:h-700"
      />
    </picture>
  );
};

const HowDoesItWorkMobileSkeleton = () => <Skeleton className={'aspect-square w-full rounded'} />;
const HowDoesItWorkDesktopSkeleton = ({items}: {items: CardItem[]}) => (
  <div className={'grid gap-24 grid-cols-3 grid-rows-1'}>
    {items.map((_, idx) => (
      <Skeleton key={idx} className={'h-500 rounded'} />
    ))}
  </div>
);

export default LanguagePage;
