import {useCallback, useEffect, useState} from 'react';
import {usePrevious} from './use-previous';

interface UseModalState {
  isOpen: boolean;
  hide: () => void;
  show: () => void;
}

interface UseModalProps {
  defaultValue?: boolean;
  onClose?: () => void;
}

/*
  Helper state for modals, provide isOpen and hide/show function
 */
export const useModalState = (props: UseModalProps = {}): UseModalState => {
  const {defaultValue = false, onClose} = props;
  const [isOpen, setIsOpen] = useState(defaultValue);
  const prevModalIsOpen = usePrevious(isOpen);
  const hide = useCallback(() => setIsOpen(false), [setIsOpen]);
  const show = useCallback(() => setIsOpen(true), [setIsOpen]);

  useEffect(() => {
    if (prevModalIsOpen && !isOpen && onClose) {
      onClose();
    }
  }, [prevModalIsOpen, isOpen, onClose]);

  return {
    isOpen,
    hide,
    show,
  };
};
