import {useMemo} from 'react';
import Helmet from 'react-helmet';
import {useLocation} from 'react-router-dom';

const CanonicalLinkHead = () => {
  const location = useLocation();
  const canonicalUrl = useMemo(() => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    return `${baseUrl}${location.pathname}`;
  }, [location.pathname]);

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalLinkHead;
