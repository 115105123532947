import {UserType} from '../../shared/user-type';
import {formatAmountCents} from '../../shared/format-cents';
import {useAppSelector} from './use-app-selector';
import {IUser, WelcomeMessage} from '../../shared/user';
import {isUserAdmin} from '../../shared/is-user-admin';
import {Language} from '../../shared/language';
import {isEmptyObject} from '../../shared/is-empty-object';
import {WithdrawalMethod} from '../../shared/saved-withdrawal-method';

export function useUserOrNull(): IUser | null {
  const user = useAppSelector((state) => {
    return state.user.data;
  });
  if (!user || isEmptyObject(user)) {
    return null;
  }
  return user as IUser;
}

export function useUser(): IUser {
  const user = useUserOrNull();
  if (!user) {
    throw Error('Current user not found');
  }
  return user;
}

export function useLimitedAccess(): boolean {
  const user = useUser();
  // user has limited access if they have pending verification
  return Boolean(user?.pendingEmailVerification);
}

export function useUserId() {
  const userId = useUser()?.userId;

  if (!userId) {
    throw Error("Can't find user id");
  }

  return userId;
}

function isValidUserType(userType: unknown): userType is UserType {
  return userType === 'model' || userType === 'customer';
}

export function useUserAdmin(): boolean {
  const userId = useUserId();
  return isUserAdmin(userId);
}

export function useUserType(): UserType {
  const userType = useUser()?.type;

  if (!isValidUserType(userType)) {
    throw Error(`Encountered an unexpected user type: ${userType}`);
  }

  return userType;
}

export function useUserProfilePhoto(): string {
  return useUser()?.settings?.profilePhoto ?? '';
}

export function useHasUserTelegramConnected(): boolean {
  return Boolean(useUser()?.telegramId);
}

export function useUserWelcomeMessage(): WelcomeMessage | undefined {
  return useUser()?.settings?.welcomeMessage;
}

export function useUserEmail(): string {
  return useUser()?.email ?? '';
}

export function useUserName() {
  return useUser()?.settings?.name ?? '';
}

export function useUserAge() {
  return useUser()?.settings?.age?.toString() ?? '';
}

export function useUserBalanceCents() {
  return useUser()?.balance ?? 0;
}

export function useUserFormattedBalance(): {balanceOnHold: string; balance: string} {
  const balance = useUserBalanceCents();
  const amountOfMoneyOnHold = useAppSelector((state) => state.user.amountCentsOnHold);
  const formattedBalanceOnHold = formatAmountCents(amountOfMoneyOnHold);
  const formattedUserBalance = formatAmountCents(balance - amountOfMoneyOnHold);
  return {
    balanceOnHold: formattedBalanceOnHold,
    balance: formattedUserBalance,
  };
}

export function useIsUserVerified() {
  const user = useUser();
  if (user.type !== 'model') {
    return false;
  }

  return user?.verified ?? false;
}

export function useDoesUserNeedVerification() {
  const userType = useUserType();
  const isVerified = useIsUserVerified();

  // Unverified models need to be verified
  return userType === 'model' && !isVerified;
}

export function useUserSavedPaymentMethods() {
  return useAppSelector((state) => state.savedPaymentMethods.methods);
}

export function useUserSavedWithdrawalMethods() {
  return useAppSelector((state) => state.savedWithdrawalMethods.methods);
}

export function useUserSavedWithdrawalMethod(method: WithdrawalMethod) {
  return useAppSelector((state) => state.savedWithdrawalMethods.methods.find((swm) => swm.method === method));
}

export function useUserLanguageOrNull(): Language | null {
  const user = useUserOrNull();
  if (!user) {
    return null;
  }
  return user?.language ?? 'en';
}
