import {useEffect} from 'react';

export const useYandexSEO = () => {
  useEffect(() => {
    const markAsLoaded = () => {
      if (!window.YandexRotorSettings.IsLoaded) {
        window.YandexRotorSettings.IsLoaded = true;
      }
    };

    if (document.readyState === 'complete') {
      markAsLoaded();
    } else {
      window.addEventListener('load', markAsLoaded);
    }

    return () => {
      window.removeEventListener('load', markAsLoaded);
    };
  }, []);
};
