import {useI18n} from '../../../../hooks/use-i18n';
import {CreateAccountModalHandler} from '../CreateAccountModal/CreateAccountModal';
import {LoginModalHandler} from './LoginModal';

const LoginModalBottomDescription = () => {
  const {T} = useI18n();
  return (
    <div className={'mt-14 self-center text-center text-base text-black/60'}>
      {/* <T
        keyName={'do-not-have-account'}
        params={{
          button: (text) => (
            <button
              type={'button'}
              className={'btn-base link link--primary-gradient'}
              onClick={() => {
                CreateAccountModalHandler.show();
                LoginModalHandler.hide();
              }}
            >
              {text}
            </button>
          ),
        }}
      /> */}
    </div>
  );
};

export default LoginModalBottomDescription;
