import {Modal} from '../ui/Modal';
import {useI18n} from '../hooks/use-i18n';
import classNames from 'classnames';
import {capitalize} from '../util/helpers/capitalize';
import {useCallback} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  title?: string;
  description?: string;
  isOpen: boolean;
  hide: () => void;
  showButton?: boolean;
  buttonText?: string;
  descriptionClassName?: string;
  buttonClassName?: string;
  onClick?: () => void;
}

export const AlertModal = (props: Props) => {
  const {
    isOpen,
    hide,
    title,
    description,
    showButton = true,
    buttonText,
    descriptionClassName,
    buttonClassName,
    onClick,
  } = props;
  const {t} = useI18n();
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      hide();
      onClick?.();
    },
    [hide, onClick],
  );
  return (
    <Modal isOpen={isOpen} hide={hide} className={'w-full px-20 py-34 text-center lg:w-330 lg:p-50'}>
      <div className="mb-12 text-center font-bold">{title}</div>
      <div className={twMerge('text-gray', descriptionClassName)}>{description}</div>
      <button
        type="button"
        className={classNames(twMerge('btn btn--primary btn--md mt-25 w-full', buttonClassName), {
          hidden: !showButton,
        })}
        onClick={handleClick}
      >
        {buttonText ?? capitalize(t('okay'))}
      </button>
    </Modal>
  );
};
