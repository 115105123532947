import {useI18n} from '../../../hooks/use-i18n';

interface Props {
  errKey: string | null;
}

export const LoginError = (props: Props) => {
  const {errKey} = props;
  const {t} = useI18n();

  if (!errKey) {
    return null;
  }

  return (
    <div
      data-testid="password-error"
      className={'mt-24 max-w-full mx-auto animate-appearance text-nano font-semibold text-red lg:text-tiny'}
    >
      {t(errKey)}
    </div>
  );
};
