import axios from 'axios';
import TrafficAnalyticsService from '../analytics-service/traffic-analytics-service';
import {sleep} from '../../util/helpers/sleep';

class PostbackService {
  private readonly trafficAnalyticsService: TrafficAnalyticsService;
  private readonly FBA_PIXEL_ID = '1822864661801197';
  private readonly FBA_SOURCE = 'inst_fba';

  constructor(trafficAnalyticsService: TrafficAnalyticsService) {
    this.trafficAnalyticsService = trafficAnalyticsService;
  }

  async init() {
    const pixel = this.trafficAnalyticsService.getUTMParams()?.utm?.pixel ?? '';
    const source = this.trafficAnalyticsService.getUTMParams()?.utm?.source ?? '';
    await this.waitForFbq();

    // const isInitialized = this.isPixelInitialized(pixel);
    // if (isInitialized) {
    // Pixel already initialized
    // return;
    // }

    if (source === this.FBA_SOURCE) {
      // One of the marketing teams uses persistent pixelId
      window.fbq('init', this.FBA_PIXEL_ID);
      window.fbq('track', 'PageView');
    }

    if (pixel) {
      // Log pixel id if it was in the URL params
      window.fbq('init', pixel);
      window.fbq('track', 'ViewContent');
    }
  }

  async initLanding() {
    const source = this.trafficAnalyticsService.getUTMParams()?.utm?.source ?? '';
    await this.waitForFbq();
    if (source === this.FBA_SOURCE) {
      window.fbq('init', this.FBA_PIXEL_ID);
      window.fbq('track', 'PageView');
    }
  }

  async trackLead() {
    const source = this.trafficAnalyticsService.getUTMParams()?.utm?.source ?? '';
    await this.waitForFbq();
    if (source === this.FBA_SOURCE) {
      window.fbq('track', 'Lead');
    }
  }

  async sendEvent() {
    try {
      const utmParams = this.trafficAnalyticsService.getUTMParams();
      if (!utmParams) return;

      const sub2 = utmParams.utm.sub2;

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      if (sub2) {
        await axios.get(`https://avtodolph.space/fe9fdb5/postback?subid=${sub2}&status=lead&payout=REPLACE`);
      }
    } catch (e) {
      console.error('Error sending postback', e);
    }
  }

  private isPixelInitialized(pixelId: string): boolean {
    return Boolean(window?.fbq?.getState()?.pixels?.some((pixel: {id: string}) => pixel.id === pixelId));
  }

  private async waitForFbq(timeout: number = 10000): Promise<void> {
    const startTime = Date.now();
    const delayMS = 100;

    while (Date.now() - startTime < timeout) {
      if (window.fbq) {
        return;
      }
      await sleep(delayMS);
    }

    throw new Error('Facebook Pixel initialization timeout');
  }
}

export default PostbackService;
