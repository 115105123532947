import Helmet from 'react-helmet';
import {useI18n} from '../hooks/use-i18n';

interface Props {
  title: string;
}

const LocalizedPageTitle = (props: Props) => {
  const {title} = props;
  const {t} = useI18n();

  return (
    <Helmet>
      <title>{t(title)}</title>
    </Helmet>
  );
};

export default LocalizedPageTitle;
