import {memo, useEffect, lazy, useState} from 'react';
import {useI18n} from '../../../hooks/use-i18n';
import {CustomerModelSwitcher} from './CustomerModelSwitcher';
import {di} from '../../../dependency-injector';
import {RenderWithSuspense} from '../../../components/RenderWithSuspense';
import HowDoesItWork from './HowDoesItWork';
import {useIsMobile} from '../../../redux/hooks/use-is-mobile';
import {useParams} from 'react-router';
import {CreateAccountModalHandler, LoginModalHandler} from '../../login';
import DirectLogin, {DirectLoginHandler} from '../../login/components/DirectLogin';

// Lazy load components
const WhyAnonFunBetter = lazy(() => import(/* webpackPrefetch: true */ './WhyAnonFunBetter'));
const SendPhotosBelowChin = lazy(() => import(/* webpackPrefetch: true */ './SendPhotosBelowChin'));
const MainFAQ = lazy(() => import(/* webpackPrefetch: true */ './MainFAQ'));
const MainFooter = lazy(() => import(/* webpackPrefetch: true */ './MainFooter'));
const BackToTopButton = lazy(() => import(/* webpackPrefetch: true */ '../../../components/BackToTopButton'));

interface Props {
  showLoginModalOnMount: boolean;
}

export const Main = memo((props: Props) => {
  const {showLoginModalOnMount} = props;
  const {t, T} = useI18n();
  const isMobile = useIsMobile();
  const [isCustomer, setIsCustomer] = useState(true);
  const {verifier, token, method} = useParams();

  useEffect(() => {
    // Show login modal
    if (showLoginModalOnMount) {
      LoginModalHandler.show();
    }
  }, [showLoginModalOnMount]);

  useEffect(() => {
    if (method === 'twitter' && !!verifier && !!token) {
      // perform twitter login
      LoginModalHandler.twitterLogin?.(token, verifier);
    } else if (method === 'direct') {
      DirectLoginHandler.login();
    }
  }, [verifier, token, method]);

  useEffect(() => {
    di.trafficAnalyticsService.trackVisit();
  }, []);
  const onJoinClick = () => {
    di.trafficAnalyticsService.trackClickRegister();
    LoginModalHandler.show();
  };
  return (
    <>
      <DirectLogin />
      <div className="overflow-hidden h-screen flex items-center justify-center">
            <button className="btn btn--md btn--primary mx-auto min-w-100 w-175" onClick={onJoinClick}>
              {t('log-in')}
            </button>
      </div>
    </>
  );
});
