import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.scss';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './redux';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GOOGLE_OAUTH_CLIENT_ID} from './shared/google-oauth-client-id';
import {useHandleIsMobile} from './redux/hooks/use-is-mobile';
import {router} from './router';
import {TolgeeProvider} from '@tolgee/react';
import {di} from './dependency-injector';
import {useUserCountry} from './hooks/use-user-country';
import * as Sentry from '@sentry/react';
import {landingRouter} from './router.landing';

Sentry.init({
  dsn: 'https://82c7b6df6f1a2be11cc6d821ad5d69bb@o420380.ingest.us.sentry.io/4508240846454784',
  integrations: [],
});

function App() {
  useHandleIsMobile();
  useUserCountry();

  const selectedRouter = process.env.REACT_APP_BUILD_TARGET === 'landing' ? landingRouter : router;

  return <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>{selectedRouter}</GoogleOAuthProvider>;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <TolgeeProvider tolgee={di.translationService.instance}>
        <App />
      </TolgeeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
